import React, { useState, useRef, useEffect } from "react";
import {
  FormGroup,
  Container,
  Row,
  Col,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
  Badge,
} from "reactstrap";
import { CheckBadgeIcon } from "@heroicons/react/20/solid";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import settings from "settings";

import { Formik, Form, Field } from "formik";
import moment from "moment";
import {
  RenderTextFieldWithoutAutoComplete,
  RenderButton,
} from "../../components";
import networkImage from "helpers/networkImage";
import { EngagementTag, FollowerTag } from "components/tags";
import Paginator from "../../components/pagination/pagination";
import TrialBanner from "components/banners/TrialBanner";
import noimg from "../../img/avatar.svg";
import defaultImg from "../../img/user-circle-outline.svg";
import "./splashScreen.scss";
import GlobalLoadScreen from "modules/globalLoadScreen/GlobalLoadScreen";

const DropdownItems = (items, selectUser, translate) => {
  const DropdownItems = items.map((item) => (
    <DropdownItem
      key={item.user_id}
      className="infulence-dropdown-list-2"
      onClick={() => selectUser(item)}
    >
      <h1 className="report-analyze">{translate("reports.generate")}</h1>
      <Container>
        <Row>
          <Col md="8" xs="8">
            <Row className="generate-report-row">
              <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                <div className="account-image">
                  {item.picture ? (
                    <img
                      src={item.picture}
                      alt="profile pic"
                      width="50px"
                      height="50px"
                      className="rounded-circle"
                      onError={(e) => (e.target.src = defaultImg)}
                    />
                  ) : (
                    <UserCircleIcon
                      className="user-circle rounded-circle"
                      fill="#00c2e6b3"
                      width={50}
                      height={50}
                      strokeWidth={1}
                    />
                  )}
                  {networkImage(item.type)}
                </div>
              </span>
              <div className="d-flex align-items-center">
                <h4 className="m-0">{item.fullname || item.username}</h4>
                {item.is_verified && (
                  <CheckBadgeIcon
                    className="h-5 w-5 ml-2 mb-1"
                    fill="#00c2e6"
                    width={25}
                    height={25}
                  />
                )}
                {item.user_id === `${item.username}-${item.type}` &&
                  networkImage(item.type)}
              </div>
            </Row>
          </Col>
          {item.followers > 0 && (
            <Col md="4" xs="2" className="text-center my-auto follower-font">
              <FollowerTag followers={item.followers}></FollowerTag>
            </Col>
          )}
        </Row>
      </Container>
    </DropdownItem>
  ));
  return DropdownItems;
};

const SplashScreen = (props) => {
  const { t } = useTranslation();

  const {
    pages: { reports },
    providers: { calendly },
  } = settings;

  const { onSubmitSpashScreen } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [reportsTextQuery, setReportsTextQuery] = useState("");
  const [selectedUser, setUserName] = useState({
    fullname: null,
    username: null,
    type: null,
  });

  const firstTimeRender = useRef(true);

  useEffect(() => {
    if (!firstTimeRender.current && selectedUser.username) {
      onSubmitSpashScreen(selectedUser);
    }
  }, [selectedUser, onSubmitSpashScreen]);

  useEffect(() => {
    firstTimeRender.current = false;
  }, []);

  const openDropdown = () => setDropdownOpen((prevState) => !prevState);

  const selectUser = (user) => {
    setUserName({
      fullname: user.fullname,
      username: user.username || user.custom_name || user.user_id,
      type: user.type,
    });
  };

  return !props.loginUser ? (
    <GlobalLoadScreen />
  ) : (
    <Container className="main-site-content splash report-splash">
      {props.subscriptionDetails?.status === "trialing" && calendly.enabled ? (
        <TrialBanner type="reports" loginUser={props.loginUser} />
      ) : null}
      <Row>
        <Col className="is-search-block search-wrap">
          <h1 className="page-title">{t("reports.splash.title")}</h1>
          <p className="sub-text text-grey">
            {t("reports.splash.description")}
          </p>
          <Formik initialValues={props.initialValues}>
            {() => (
              <Form className="is-searchform">
                <InputGroup>
                  <div className="row w-100">
                    <div className="col-xl-6 col-md-6 col-sm-12">
                      <InputGroup className="search-wrap relevance-dropdown-parent mb-0">
                        <Dropdown
                          className="preview-accounts"
                          isOpen={
                            dropdownOpen &&
                            props.availableAccounts &&
                            props.availableAccounts.length > 0
                          }
                          toggle={openDropdown}
                        >
                          <DropdownToggle tag="div">
                            <Field
                              name="userNameSplash"
                              id="searchFormSplash"
                              className="search-Splash-text"
                              placeholder={t("reports.splash.placeholder")}
                              onKeyUp={(e) => {
                                if (e.key === "Enter" && selectedUser.user_id) {
                                  props.onSubmitSpashScreen(selectedUser);
                                } else {
                                  props.handleSearchAccounts(e.target.value);
                                  setDropdownOpen(true);
                                  setUserName({
                                    fullname: null,
                                    username: null,
                                  });
                                }
                              }}
                              customvalue={selectedUser.username}
                              component={RenderTextFieldWithoutAutoComplete}
                            />
                          </DropdownToggle>
                          {props.availableAccounts && (
                            <DropdownMenu className="ddmenu-col preview-accounts-dropdown width-md-2">
                              {props.availableAccounts &&
                                DropdownItems(
                                  props.availableAccounts,
                                  selectUser,
                                  t
                                )}
                            </DropdownMenu>
                          )}
                        </Dropdown>
                      </InputGroup>
                    </div>
                  </div>
                </InputGroup>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>

      <Row className="search-results-wrap">
        <Col md="12">
          <div className="table-responsive table-outer user-profile-table">
            <Table hover borderless>
              <thead>
                <tr>
                  <th width="25%" className="my-reports">
                    {t("reports.splash.chart.title")}
                  </th>
                  <th width="15%" className="text-left mobile-view-past-report">
                    {t("reports.splash.chart.followers")}
                  </th>
                  <th width="10%" className="text-left mobile-view-past-report">
                    {t("reports.splash.chart.engagements")}
                  </th>
                  <th width="15%" className="text-left mobile-view-past-report">
                    {t("reports.splash.chart.date")}
                  </th>
                  <th width="20%" className="table-filter-input">
                    {reports.search.enabled && (
                      <Formik initialValues={props.initialValues}>
                        {() => (
                          <Form className="is-searchform">
                            <FormGroup className="form-field-col">
                              <Field
                                component={RenderTextFieldWithoutAutoComplete}
                                className="text-search-icon-small"
                                name="reportSearch"
                                id="reportSearchInput"
                                placeholder={t(
                                  "reports.splash.filter_placeholder"
                                )}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    props.handleSubmitReportsFilter(
                                      reportsTextQuery
                                    );
                                  }
                                }}
                                onChange={(e) => {
                                  setReportsTextQuery(e.target.value);
                                }}
                                customvalue={reportsTextQuery}
                              />
                            </FormGroup>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.pastExportsReports &&
                props.pastExportsReports.reports_generated &&
                props.pastExportsReports.reports_generated.length ? (
                  props.pastExportsReports.reports_generated.map(
                    (report, index) => (
                      <tr key={index}>
                        <td className="tb-head d-flex">
                          <div className="account-image">
                            <img
                              src={report.picture ? report.picture : noimg}
                              alt="profile pic"
                              width="50px"
                              height="50px"
                              className="rounded-circle"
                              onError={(e) => (e.target.src = defaultImg)}
                            />
                            {networkImage(report.type)}
                          </div>

                          <div className="d-flex align-items-center text-wrap">
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href={report.url}
                              className="link"
                            >
                              <h4 className="m-0">
                                {report.type === "youtube"
                                  ? report.fullname || report.social_id
                                  : `@${report.username}`}
                              </h4>
                            </a>
                            {report.demo ? (
                              <Badge
                                className={`fs--2 badge-soft-neutral ml-2`}
                              >
                                {t("reports.splash.chart.example")}
                              </Badge>
                            ) : null}
                          </div>
                        </td>
                        <td className="text-left mobile-view-past-report">
                          <FollowerTag
                            followers={report.followers || 0}
                          ></FollowerTag>
                        </td>
                        <td className="text-left mobile-view-past-report">
                          <EngagementTag
                            engagements={
                              report.followers * report.engagement_rate || 0
                            }
                          ></EngagementTag>
                        </td>
                        <td className="text-left mobile-view-past-report">
                          {!report.demo &&
                            moment(report.created).format("MMMM DD, YYYY")}
                        </td>
                        <td align="center">
                          <RenderButton
                            onClick={() => props.handleReportsAnalyze(report)}
                            className="btn btn-outline-primary"
                          >
                            {window.innerWidth < 676
                              ? t("reports.splash.chart.view")
                              : t("reports.splash.chart.view_report")}
                          </RenderButton>
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan="6" align="center">
                      {t("errors.no_record")}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>

      {props.pastExportsReports &&
      props.pastExportsReports.total &&
      props.pastExportsReports.total > 10 ? (
        <Paginator
          activePage={props.state.activeReportsPage}
          itemsPerPage={10}
          totalItems={props.pastExportsReports.total}
          handlePageChange={(pageNumber) => props.handlePageChange(pageNumber)}
        />
      ) : null}
    </Container>
  );
};

export default SplashScreen;
