import mainbrand from "./tensor-icon.svg";
import favicon from "./favicon.ico";

export default {
  versionName: "Tensor Social",
  exportName: "tensor_export_",
  api_token: {
    enabled: true,
  },
  facebook: {
    enabled: false,
  },
  mainbrand,
  favicon,
  pages: {
    dashboard: {
      enabled: true,
      header: {
        enabled: false,
        welcome: true,
        getting_started: true,
      },
    },
    discovery: {
      enabled: true,
      duplicates: false,
      edit_columns: false,
      offset: true,
    },
    reports: {
      enabled: true,
      search: {
        enabled: true,
      },
      share: {
        enabled: true,
      },
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
      disabled: [],
    },
    campaign_manager: {
      enabled: true,
    },
    campaign_results: {
      enabled: false,
    },
    creator_lists: {
      enabled: true,
    },
    follower_list: {
      enabled: false,
    },
    signup: {
      enabled: false
    }
  },
  filters: {
    tooltip: true,
    categories: {
      psychographics: {
        enabled: true,
        column: 4,
      },
      demographics: {
        enabled: true,
        column: 4,
      },
      geographics: {
        enabled: true,
        column: 2,
      },
      performance: {
        enabled: true,
        column: 6,
        filter_column: 4,
      },
      other: {
        enabled: true,
        column: 6,
        contacts_filter: 4,
      },
    },
    disabled: [],
  },
  providers: {
    stripe: {
      enabled: true,
    },
    calendly: {
      enabled: true,
    },
    pathfix: {
      enabled: true,
      services: ["csv", "googlesheets", "hubspot"],
    },
  },
};
