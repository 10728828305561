import base from "../default/settings";
import mainbrand from "./ignite.svg";
import favicon from "./favicon.ico";

export default {
  ...base,
  versionName: "Influencer Ignite",
  exportName: "influencer_ignite_export_",
  api_token: {
    enabled: false,
  },
  facebook: {
    enabled: false,
  },
  mainbrand,
  favicon,
  pages: {
    dashboard: {
      enabled: true,
      header: {
        enabled: false,
        welcome: true,
        getting_started: false,
      },
    },
    discovery: {
      enabled: true,
      duplicates: false,
      edit_columns: false,
      offset: false,
    },
    reports: {
      enabled: true,
      search: {
        enabled: false,
      },
      share: {
        enabled: false,
      },
      months: [
        "一月",
        "二月",
        "三月",
        "四月",
        "五月",
        "六月",
        "七月",
        "八月",
        "九月",
        "十月",
        "十一月",
        "十二月",
      ],
      disabled: ["ethnicity"],
    },
    campaign_manager: {
      enabled: false,
    },
    campaign_results: {
      enabled: false,
    },
    creator_lists: {
      enabled: true,
    },
    follower_list: {
      enabled: false,
    },
    signup: {
      enabled: false
    }
  },
  filters: {
    tooltip: false,
    categories: {
      psychographics: {
        enabled: true,
        column: 5,
      },
      demographics: {
        enabled: true,
        column: 5,
      },
      geographics: {
        enabled: true,
        column: 2,
      },
      performance: {
        enabled: true,
        column: 5,
        filter_column: 6,
      },
      other: {
        enabled: true,
        column: 7,
        contacts_filter: 4,
      },
    },
    disabled: ["keyword", "ethnicity", "growth", "university"],
  },
   providers: {
    stripe: {
      enabled: false,
    },
    calendly: {
      enabled: false,
    },
    pathfix: {
      enabled: false,
      services: ["csv"],
    },
  },
};
