import React, { useState } from "react";
import { Row, Col, Input, Tooltip, UncontrolledTooltip } from "reactstrap";
import { RenderButton } from "../../components";
import { LinkIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import { showTooltip } from "../../actions";
import settings from "settings";

const APIToken = ({
  handleGenerateTokenClick,
  subscriptionDetails,
  ...props
}) => {
  const [isclipboardCopied, setClipboardCopied] = useState(false);

  const handleCopyText = (e) => {
    const textField = document.createElement("textarea");
    textField.innerText = subscriptionDetails?.api.api_token;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();

    setClipboardCopied(true);

    setTimeout(() => {
      setClipboardCopied(false);
    }, 2000);
  };

  const isSubscribed = () => {
    return (
      subscriptionDetails?.subscription === "premium" &&
      subscriptionDetails?.status !== "trialing"
    );
  };

  return (
    <Row className="d-flex align-items-center mb-4">
      <Col md="12" className="text-wrapper">
        <h1 className="page-heading">Manage API</h1>
        <p className="sub-text text-grey">
          Generate a token for use in the{" "}
          <a
            href="https://docs.tensorsocial.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            {settings.versionName} API
          </a>
        </p>
      </Col>
      {subscriptionDetails?.api.access ? (
        <>
          <Col className="col-10">
            <Input
              className="api-token-input"
              disabled
              value={subscriptionDetails?.api.api_token}
            />
          </Col>
          <Col className="col-2">
            <RenderButton
              id="clipboardCopy"
              className="btn btn-primary token-button"
              onClick={handleCopyText}
            >
              <LinkIcon width={25} height={25} />
            </RenderButton>
            <Tooltip
              placement="top"
              className="tooltip-content"
              target="clipboardCopy"
              isOpen={isclipboardCopied}
            >
              {showTooltip("clipboardCopy")}
            </Tooltip>
          </Col>
        </>
      ) : (
        <Col md="6">
          <RenderButton
            className="btn btn-primary token-button generate-button float-right"
            disabled={!isSubscribed()}
            onClick={() => handleGenerateTokenClick()}
          >
            <span id="tokenGenerate" className="d-flex align-items-center">
              <PlusSmallIcon className="mr-3" width={25} height={25} />
              Generate Token
            </span>
          </RenderButton>
          <UncontrolledTooltip
            className={isSubscribed() ? "tooltip-disabled" : ""}
            target="tokenGenerate"
          >
            {showTooltip("tokenGenerate")}
          </UncontrolledTooltip>
        </Col>
      )}
    </Row>
  );
};

export default APIToken;
