import React, { useEffect, useState } from "react";
import settings from "settings";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  Tooltip,
} from "reactstrap";
import {
  ArrowLeftIcon,
  ArrowTopRightOnSquareIcon,
  LinkIcon,
} from "@heroicons/react/24/outline";
import { UserPlusIcon } from "@heroicons/react/20/solid";
import { RenderButton } from "../../components";
import { showTooltip } from "../../actions";
import history from "../../history";
import networkImage from "helpers/networkImage";
import noimg from "../../img/MaskGroup.png";
import defaultImg from "../../img/user-circle-outline.svg";
import { Formik, Form } from "formik";
import { ReactComponent as EnvelopIcon } from "../../img/envelope.svg";
import { ReactComponent as PhoneIcon } from "../../img/phone.svg";
import CreatorListModal from "modules/identification/modals/creatorListModal";

const ProfileSection = ({
  _blank,
  loginUser,
  accountOverviewDetails,
  downloadPdf,
  getAccountOverview,
  getCreatorLists,
  addCreatorList,
  updateCreatorList,
  creatorLists,
  dismissCreatorListModal,
  ...props
}) => {
  const { t } = useTranslation();
  const reportUrl = `${process.env.REACT_APP_URL}/reports/${accountOverviewDetails.report_info.report_id}`;

  const [isclipboardCopied, setClipboardCopied] = useState(false);

  const [isReportsModalOpen, setIsReportsModalOpen] = useState(false);
  const [isCreatorListModalOpen, setIsCreatorListModalOpen] = useState(false);
  const [creatorFormattedForList, setCreatorFormattedForList] = useState([]);
  const [formattedCreatorLists, setFormattedCreatorLists] = useState([]);

  const toggleReportsModal = () => setIsReportsModalOpen(!isReportsModalOpen);
  const toggleCreatorListModal = () =>
    setIsCreatorListModalOpen(!isCreatorListModalOpen);

  const addCreatorListHandler = (listName) => {
    addCreatorList({
      name: listName,
      creators: creatorFormattedForList,
    });
  };

  const updateCreatorListHandler = (listId) => {
    updateCreatorList({
      _id: listId,
      creators: creatorFormattedForList,
    });
  };

  const handleCopyText = (e) => {
    const textField = document.createElement("textarea");
    textField.innerText = reportUrl;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();

    setClipboardCopied(true);

    setTimeout(() => {
      setClipboardCopied(false);
    }, 2000);
  };

  useEffect(() => {
    if (loginUser) {
      getCreatorLists();
    }
  }, [isCreatorListModalOpen, getCreatorLists, loginUser]);

  useEffect(() => {
    const profile = accountOverviewDetails?.user_profile;
    const formattedCreator = [
      {
        reach: profile?.followers,
        engagements: profile?.engagements,
        network: profile?.type,
        imageUrl: profile?.picture,
        handle: profile?.username || profile?.fullname,
        user_id: profile?.user_id,
      },
    ];
    setCreatorFormattedForList(formattedCreator);
  }, [accountOverviewDetails.user_profile]);

  useEffect(() => {
    setIsCreatorListModalOpen(dismissCreatorListModal);
  }, [dismissCreatorListModal]);

  useEffect(() => {
    const formattedLists = creatorLists?.map((list) => ({
      label: list.name,
      value: list._id,
    }));
    setFormattedCreatorLists(formattedLists);
  }, [creatorLists]);

  return (
    <Row>
      <Modal
        id="lookAlikesDownload"
        isOpen={isReportsModalOpen}
        toggle={toggleReportsModal}
      >
        <ModalHeader toggle={toggleReportsModal}>
          <span className="tooltip-button-wrap">
            <span className="text-tooltip">{t("reports.chart.contact")}</span>{" "}
            <span href="#" id="lookAlikesModal" className="tooltip-icon"></span>
          </span>
          <RenderButton
            onClick={() => downloadPdf("lookAlikesDownload")}
            className="download-icon-button mg-left-15 link"
          >
            {t("reports.download")}
          </RenderButton>
        </ModalHeader>
        <ModalBody>
          <Table className="modal-table">
            <thead>
              <tr>
                <th>{t("reports.chart.type")}</th>
                <th>{t("reports.chart.value")}</th>
              </tr>
            </thead>
            <tbody>
              {accountOverviewDetails &&
                accountOverviewDetails.user_profile &&
                accountOverviewDetails.user_profile.contacts &&
                accountOverviewDetails.user_profile.contacts.map(
                  (item, index) => (
                    <tr key={index}>
                      <td className="tb-head">
                        <div className="country-text-wrap">
                          <div className="country-name">{item.type}</div>
                        </div>
                      </td>

                      <td className="tb-head">
                        <div className="country-text-wrap">
                          <div className="country-name">
                            {item.formatted_value}
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                )}
            </tbody>
          </Table>
        </ModalBody>
      </Modal>

      <Col md="6" className="mx-auto">
        <div className="heading-w-img">
          <Row className="justify-content-center">
            <div className="image-col">
              <img
                src={
                  accountOverviewDetails &&
                  accountOverviewDetails.user_profile &&
                  accountOverviewDetails.user_profile.picture
                    ? accountOverviewDetails.user_profile.picture
                    : noimg
                }
                onError={(e) => (e.target.src = defaultImg)}
                alt="img"
              />
              {networkImage(accountOverviewDetails.user_profile.type)}
            </div>
          </Row>
          <Row className="justify-content-center">
            <div className="heading">
              <h1>
                {accountOverviewDetails &&
                accountOverviewDetails.user_profile &&
                accountOverviewDetails.user_profile.is_verified ? (
                  <span>
                    {accountOverviewDetails &&
                      accountOverviewDetails.user_profile &&
                      (accountOverviewDetails.user_profile.fullname ||
                        accountOverviewDetails.user_profile.username)}
                  </span>
                ) : (
                  accountOverviewDetails &&
                  accountOverviewDetails.user_profile &&
                  (accountOverviewDetails.user_profile.fullname ||
                    accountOverviewDetails.user_profile.username)
                )}
              </h1>
              <div className="sub-head">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={
                    accountOverviewDetails &&
                    accountOverviewDetails.user_profile &&
                    accountOverviewDetails.user_profile.url
                  }
                >
                  {accountOverviewDetails &&
                    accountOverviewDetails.user_profile &&
                    (accountOverviewDetails.user_profile.username ||
                      accountOverviewDetails.user_profile.fullname)}
                </a>
                {accountOverviewDetails &&
                accountOverviewDetails.user_profile &&
                accountOverviewDetails.user_profile.is_business
                  ? `, ${t("reports.business")}`
                  : ""}{" "}
              </div>
              {accountOverviewDetails &&
                accountOverviewDetails.user_profile &&
                accountOverviewDetails.user_profile.contacts &&
                accountOverviewDetails.user_profile.contacts.length > 1 && (
                  <br />
                )}

              {accountOverviewDetails &&
                accountOverviewDetails.user_profile &&
                accountOverviewDetails.user_profile.contacts &&
                accountOverviewDetails.user_profile.contacts &&
                accountOverviewDetails.user_profile.contacts &&
                accountOverviewDetails.user_profile.contacts.filter(
                  (i) => i.type === "email"
                ).length > 0 && (
                  <div className="sub-head">
                    <span className="icon-container">
                      <EnvelopIcon
                        className="link-icon"
                        width={20}
                        height={20}
                      />
                    </span>
                    {accountOverviewDetails.user_profile.contacts &&
                      accountOverviewDetails.user_profile.contacts.filter(
                        (i) => i.type === "email"
                      )[0].value}{" "}
                  </div>
                )}
              {accountOverviewDetails &&
                accountOverviewDetails.user_profile &&
                accountOverviewDetails.user_profile.contacts &&
                accountOverviewDetails.user_profile.contacts &&
                accountOverviewDetails.user_profile.contacts &&
                accountOverviewDetails.user_profile.contacts.filter(
                  (i) => i.type === "phone"
                ).length > 0 && (
                  <div className="sub-head">
                    <span className="icon-container">
                      <PhoneIcon className="link-icon" width={20} height={20} />
                    </span>
                    {accountOverviewDetails.user_profile.contacts &&
                      accountOverviewDetails.user_profile.contacts
                        .filter((i) => i.type === "phone")[0]
                        .value.replace(/[^\d]+/g, "")
                        .replace(
                          /(\d{1})(\d{3})(\d{3})(\d{4})/,
                          "+$1 ($2) $3-$4"
                        )}{" "}
                  </div>
                )}
              {accountOverviewDetails &&
                accountOverviewDetails.user_profile &&
                accountOverviewDetails.user_profile.contacts &&
                accountOverviewDetails.user_profile.contacts.length > 0 && (
                  <div className="bottom contact-link">
                    <Button onClick={toggleReportsModal} color="link">
                      {t("reports.contact")}
                    </Button>
                  </div>
                )}
            </div>
          </Row>
          {loginUser && (
            <Row className="reports-profile-button-group">
              <Col xs="3">
                {_blank ? (
                  <RenderButton
                    className="btn btn-primary btn btn-secondary"
                    onClick={() => {
                      window.open(reportUrl, "_blank");
                    }}
                  >
                    <ArrowTopRightOnSquareIcon width={25} height={25} />
                  </RenderButton>
                ) : (
                  <RenderButton
                    className="btn btn-primary btn btn-secondary back-button"
                    onClick={() => {
                      getAccountOverview(null, true);
                      history.push("/reports");
                    }}
                  >
                    <ArrowLeftIcon
                      className="back-button-icon"
                      width={25}
                      height={25}
                    />
                  </RenderButton>
                )}
              </Col>

              <Col xs="9" className="text-right">
                {settings.pages.creator_lists.enabled ? (
                  <Formik>
                    {() => (
                      <Form>
                        {/* CreatorListModal must be inside a Formik Form */}
                        <RenderButton
                          className="btn btn-primary btn btn-secondary"
                          onClick={toggleCreatorListModal}
                        >
                          <UserPlusIcon width={25} height={25} />
                        </RenderButton>
                        <CreatorListModal
                          isOpen={isCreatorListModalOpen}
                          toggleModal={toggleCreatorListModal}
                          selectedUsers={creatorFormattedForList}
                          creatorLists={formattedCreatorLists}
                          addCreatorListHandler={addCreatorListHandler}
                          updateCreatorListHandler={updateCreatorListHandler}
                        />
                      </Form>
                    )}
                  </Formik>
                ) : null}
                {settings.pages.reports.share.enabled ? (
                  <>
                    <RenderButton
                      id="clipboardCopy"
                      className="btn btn-primary btn btn-secondary"
                      onClick={handleCopyText}
                    >
                      <LinkIcon width={25} height={25} />
                    </RenderButton>
                    {props.tooltip && (
                      <Tooltip
                        placement="top"
                        className="tooltip-content"
                        target="clipboardCopy"
                        isOpen={isclipboardCopied}
                      >
                        {showTooltip("clipboardCopy")}
                      </Tooltip>
                    )}
                  </>
                ) : null}
              </Col>
            </Row>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default ProfileSection;
