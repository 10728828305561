import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  .init({
    debug: false,
    fallbackLng: process.env.REACT_APP_LOCALE.split("-")[0],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          auth: {
            login: {
              title: "Sign in",
              description:
                "Sign in now to access the latest insights for your social media performance.",
              remember: "Remember me",
              forgot_password: "Forgot password?",
              continue: "Continue",
              no_account: "Don’t have an account?",
              sign_up: "Sign up",
              form: {
                email: "Email",
                password: "Password",
              },
            },
            register: {
              title: "Sign up",
              description:
                "Sign up now to access the latest insights for your social media performance.",
              create_account: "Create account",
              update_password: "Update Password",
              save_changes: "Save",
              existing_account: "Already have an account?",
              sign_in: "Sign In",
              form: {
                name: "Name",
                first_name: "First Name",
                last_name: "Last Name",
                email: "Email",
                company: "Company Name",
                phone: "Phone Number",
                password: "Password",
                confirm: "Confirm Password",
                current: "Current Password",
              },
            },
            forgot: {
              title: "Forgot your password?",
              description:
                "Enter your email below and we’ll send you a reset link.",
              link: "Send Reset Link",
              back: "Back to",
              sign_in: "Sign in",
              form: {
                email: "Email",
              },
            },
            sidebar: {
              balance: "Balance",
              reports: "Reports",
              exports: "Exports",
              manage: "Manage Account",
              support: "Support",
              sign_out: "Sign Out",
            },
          },
          email_match: {
            title: "Email Match",
          },
          campaign_manager: {
            title: "Campaign Manager",
            description:
              'Start tracking a new post by clicking the "Add Post" button below. Click an existing campaign to manage and view posts, creators, and performance metrics.',
            welcome: "Welcome to Campaign Management!",
            welcome_description:
              'Welcome to Campaign Management by Tensor Social, where you can view your campaign\'s posts, creators, and performance metrics. Start tracking your first campaign by entering a post URL and clicking the "Add Post" button below.',
            new_campaign: "Add Post",
            create: {
              placeholder_name: "Enter Campaign name...",
            },
            campaign_modal: {
              posts: "Posts",
              creators: "Creators",
              views: "Views",
              engagements: "Engagements",
              followers: "Followers",
            },

            // create: {
            //   title: "Start New Campaign",
            //   description:
            //     "Welcome to your new campaign. Start by filling out the form below and a Tensor Social expert will get your campaign started immediately.",
            //   label_name: "Campaign Name",
            //   placeholder_name: "Name Campaign...",
            //   label_description: "Campaign Description",
            //   placeholder_description: "Describe Campaign...",
            //   label_networks: "Target Network(s)",
            //   label_creator_list: "Preferred Creators",
            //   placeholder_creator_list: "Select a list of creators...",
            //   label_budget: "Budget",
            //   placeholder_budget: "Enter your preferred budget...",
            //   label_date: "Start Date",
            // },

            campaigns_table: {
              name_header: "Name",
              networks_header: "Networks",
              posts_header: "Posts",
              creators_header: "Creators",
              followers_header: "Followers",
              views_header: "Views",
              engagements_header: "Engagements",
              modified_header: "Last Modified",
              delete: "Delete",
            },
          },
          creator_lists: {
            title: "Creator Lists",
            description:
              "Creators found on Discovery can be easily organized into lists. Select a list below to get started reviewing, managing, and tracking your desired creators.",
            welcome: "Welcome to your Creator Lists!",
            welcome_description:
              "Welcome to your creator lists by Tensor Social. Add creators to lists straight from the Discovery page.",
            new_list: "New List",
            list_table: {
              name_header: "Name",
              networks_header: "Networks",
              creator_header: "Creator",
              creators_header: "Creators",
              followers_header: "Followers",
              reach_header: "Reach",
              engagement_header: "Engagements",
              modified_header: "Last Modified",
              date_added_header: "Date Added",
            },
          },
          dashboard: {
            title: "Dashboard",
            header: {
              title: "Connect your account to track performance",
              description:
                "All the progress at your finger tips. Add any account you are interested in to track and compare its progress aginst time and rivals.",
            },
            creator_tracking: {
              title: "Tracked Accounts",
              description: "My Accounts",
              view_report_button: "View Report",
              time_period: "Last 30 Days",
              new_creator: {
                tab_title: "Add new account",
              },
            },
            getting_started: {
              title: "Getting Started with Tensor Social",
              description:
                "Tutorials to help you navigate and use the Tensor Social platform",
              learn_more: "Learn More",
            },
            quick_searches: {
              title: "Quick Start Discovery Searches",
              description:
                "Kick-start your creator search with these Discovery presets",
            },
            search: {
              description: "Search creators",
              placeholder: "Enter account username...",
              discovery_link_count_fallback: "250M",
              discovery_link_text: "creators",
              discovery_link_appended_text: "in our database",
            },
          },
          discovery: {
            title: "Discovery",
            audience: "Audience",
            influencer: "Creator",
            results: "results found",
            keywords: "Search keywords or usernames",
            view: "View Report",
            fields: {
              select_interval: "Select interval",
              select_weight: "select weight",
              from: "From",
              to: "To",
            },
            chart: {
              title: "Past Exports",
              filters: "Filters",
              date: "Date",
              search: "Modify",
              export: "Export",
              example: "Example",
              empty: "No filters",
            },
            export: {
              title: "Export Records",
              results: "Results",
              remaining: "Exports Remaining",
              empty: "No Exports Available",
              contact: "Include Contact Details",
              contact_tooltip:
                "Contact details are now included in all exports",
              placeholder: "Number of exports",
              button: "Export",
              waiting: "We are preparing your export!",
              done: "Done",
            },
            category: {
              psychographics: "Psychographics",
              demographics: "Demographics",
              geographics: "Geographics",
              performance: "Performance",
              other: "Other",
            },
            filters: {
              culture: {
                title: "Interests",
                placeholder: {
                  audience: "Search interests",
                  influencer: "Search interests",
                },
              },
              brands: {
                title: "Brands",
                placeholder: {
                  audience: "Search brands",
                  influencer: "Search brands",
                },
              },
              lookalikes: {
                title: "Lookalikes",
                placeholder: {
                  audience: "Search lookalikes",
                  influencer: "Search lookalikes",
                },
              },
              bio: {
                title: "Bio",
                placeholder: {
                  influencer: "Search bio",
                },
              },
              age: {
                title: "Age",
              },
              gender: {
                title: "Gender",
                options: {
                  male: "Male",
                  female: "Female",
                  male_female: "Male or Female",
                  neutral: "Theme/Business Account",
                },
              },
              language: {
                title: "Language",
                placeholder: {
                  audience: "Search audience language",
                  influencer: "Search creator language",
                },
              },
              ethnicity: {
                title: "Ethnicity",
              },
              location: {
                title: "Location",
                placeholder: {
                  country: "Search country",
                  city: "Search country or city",
                },
              },
              followers: {
                title: "Followers",
                youtube: "Subscribers",
              },
              engagements: {
                title: "Engagements",
                rate: "Engagement Rate",
              },
              last_post: {
                title: "Last Post",
                youtube: "Last Upload",
                options: {
                  one_month: "1 Month",
                  three_months: "3 Months",
                  six_months: "6 Months",
                },
              },
              views: {
                title: "Views",
              },
              reels: {
                title: "Reels Plays",
              },
              account_type: {
                title: "Account Type",
                options: {
                  regular: "Regular",
                  business: "Business",
                  creator: "Creator",
                },
              },
              audience_type: {
                title: "Audience Type",
                options: {
                  any: "Any",
                  likers: "Likers",
                  followers: "Followers",
                },
              },
              partnerships: {
                title: "Partnerships",
                placeholder: {
                  influencer: "Search brands",
                },
              },
              contacts: {
                title: "Contacts",
                placeholder: {
                  influencer: "Search contacts",
                },
              },
              other: {
                verified: "Only verified accounts",
                credible: "Only credible accounts",
                private: "Exclude private accounts",
                audience: "Has audience data",
                contact: "Has contact details",
                sponsored: "Has sponsored posts",
              },
            },
          },
          interests: {
            television_film: "Television & Film",
            music: "Music",
            shopping_retail: "Shopping & Retail",
            coffee_tea_beverages: "Coffee, Tea & Beverages",
            camera_photography: "Camera & Photography",
            clothes_shoes: "Clothes, Shoes, Handbags & Accessories",
            beer_wine: "Beer, Wine & Spirits",
            sports: "Sports",
            electronics_computers: "Electronics & Computers",
            gaming: "Gaming",
            activewear: "Activewear",
            art_design: "Art & Design",
            travel_tourism: "Travel, Tourism & Aviation",
            business_careers: "Business & Careers",
            beauty_cosmetics: "Beauty & Cosmetics",
            healthcare_medicine: "Healthcare & Medicine",
            jewelry_watches: "Jewelry & Watches",
            restaurants_grocery: "Restaurants, Food & Grocery",
            toys_children: "Toys, Children & Baby",
            fitness_yoga: "Fitness & Yoga",
            wedding: "Wedding",
            tobacco_smoking: "Tobacco & Smoking",
            pets: "Pets",
            healthy_lifestyle: "Healthy Lifestyle",
            luxury_goods: "Luxury Goods",
            furniture_garden: "Home Decor, Furniture & Garden",
            friends_family: "Friends, Family & Relationships",
            cars_motorbikes: "Cars & Motorbikes",
          },
          languages: {
            italian: "Italian",
            spanish: "Spanish",
            polish: "Polish",
            korean: "Korean",
            finnish: "Finnish",
            thai: "Thai",
            malay: "Malay",
            dutch: "Dutch",
            swedish: "Swedish",
            arabic: "Arabic",
            hebrew: "Hebrew",
            danish: "Danish",
            ukrainian: "Ukrainian",
            english: "English",
            greek: "Greek",
            portuguese: "Portuguese",
            russian: "Russian",
            japanese: "Japanese",
            hindi: "Hindi",
            turkish: "Turkish",
            french: "French",
            persian: "Persian",
            catalan: "Catalan",
            hungarian: "Hungarian",
            czech: "Czech",
            german: "German",
            indonesian: "Indonesian",
            chinese: "Chinese",
            norwegian: "Norwegian",
            basque: "Basque",
            urdu: "Urdu",
            macedonian: "Macedonian",
            tagalog: "Tagalog",
            lithuanian: "Lithuanian",
            albanian: "Albanian",
            vietnamese: "Vietnamese",
          },
          reports: {
            title: "Creator Analytics",
            splash: {
              title: "Creator Analytics",
              description:
                "Enter any social handle below to get demographic, geographic, psychographic, and performance insights in a concise report.",
              placeholder: "Enter account username...",
              button: "Analyze",
              filter_placeholder: "Search past reports",
              chart: {
                title: "Past Reports",
                followers: "Followers",
                engagements: "Engagements",
                date: "Date",
                view: "View",
                view_report: "View Report",
                example: "Example",
              },
            },
            global: {
              placeholder: "Search...",
            },
            add_to_list: "Add to List",
            share: "Share",
            generate: "Generate",
            business: "business account",
            contact: "View Contact",
            influencer: "Creator",
            audience: "Audience",
            posts: "Posts",
            stories: "Stories",
            view_more: "View More",
            download: "Download PDF",
            chart: {
              contact: "Contact",
              type: "Type",
              value: "Value",
              brand: "Brand",
              country: "Country",
              state: "State",
              city: "City",
              age: "Age",
              gender: "Gender",
              interest: "Interest",
              followers: "Followers",
              likes: "Likes",
              percentage: "Percentage",
            },
            influencer_details: {
              engagements: "Engagements",
              followers: "Followers",
              subscribers: "Subscribers",
              following: "Following",
              likes: "Avg Likes",
              views: "Avg Views",
              comments: "Avg Comments",
              reels_plays: "Avg Reels Plays",
              price: "Est. Price per Post",
              location: "Location",
              paid: "Paid Post Performance",
              month: "this month",
              trending: {
                title: "Trending Analysis",
                up: "Account is trending up",
                down: "Account is trending down",
                neutral: "Account is neutral",
                bad: "Bad",
                normal: "Normal",
                good: "Good",
              },
              engagement_rate: {
                title: "Engagement Rate",
                graph: "Similar Accounts",
                accounts: "Accounts",
              },
              relevant_topics: "Relevant Topics®",
              lookalikes: "Lookalikes",
              engagements_spread: {
                title: "Engagements spread for last posts",
                date: "Post date",
                likes: "Likes",
                comments: "Comments",
                post: "Post",
                view: "View Post",
              },
              hashtags: {
                title: "Popular Hashtags",
                hashtag: "Hashtag",
                percent: "Percent",
              },
              mentions: {
                title: "Popular Mentions",
                mention: "Mention",
                percent: "Percent",
              },
              brand_affinity: "Creator Brand Affinity",
              interests: "Creator Interests",
            },
            audience_details: {
              title: "Audience Details",
              tabs: {
                followers: "Followers",
                likes: "Likes",
              },
              interests: "Audience Interests",
              lookalikes: "Audience Lookalikes",
              brand_affinity: "Audience Brand Affinity",
              reach: "Audience Reachability",
              gender: "Gender Split",
              age_gender: {
                title: "Age and Gender Split",
                age: "Age Split",
                female: "Female Age Split",
                male: "Male Age Split",
              },
              location: {
                city: "Location by City",
                state: "Location by State",
                country: "Location by Country",
                ethnicity: "Ethnicity",
                language: "Language",
              },
              followers: {
                credibility: "Followers Credibility",
                notable: "Notable Followers",
              },
              likes: {
                credibility: "Likers Credibility",
                notable: "Notable Likes",
              },
            },
            post_details: {
              tabs: {
                popular: "Popular",
                sponsored: "Sponsored",
              },
              popular: "Popular Posts",
              sponsored: "Sponsored Posts",
            },
            story_details: {
              title: "Story Details",
              reach: "Estimated Reach",
              back: "Estimated Backs / Rewatch",
              swipe_ups: "Estimated Swipe Ups",
              link_clicks: "Estimated Link Clicks",
              day: "Stories Per Day",
              impression: "Estimated Impressions",
              forwards: "Estimated Forwards",
              replies: "Estimated Replies",
              current: "Current Number of Stories",
              empty: "This account currently has no stories to display.",
              private: "This account is private. Unable to retrieve stories.",
            },
          },
          activity: {
            title: "Activity",
          },
          errors: {
            no_record: "No Record Found",
          },
        },
      },
      ja: {
        translation: {
          auth: {
            login: {
              title: "サインイン",
              description:
                "今すぐサインインして、ソーシャルメディアのパフォーマンスに関する最新の洞察にアクセスしてください。",
              remember: "私を覚えてますか",
              forgot_password: "パスワードをお忘れですか？",
              continue: "継続する",
              no_account: "アカウントをお持ちではありませんか？",
              sign_up: "サインアップ",
              form: {
                email: "Eメール",
                password: "パスワード",
              },
            },
            register: {
              title: "サインアップ",
              description:
                "今すぐサインアップして、ソーシャルメディアのパフォーマンスに関する最新の洞察にアクセスしてください。",
              create_account: "アカウントを作成する",
              update_password: "パスワードを更新する",
              save_changes: "保存",
              existing_account: "すでにアカウントをお持ちですか？",
              sign_in: "サインイン",
              form: {
                name: "名前",
                first_name: "ファーストネーム",
                last_name: "苗字",
                email: "Eメール",
                company: "会社名",
                phone: "電話番号",
                password: "パスワード",
                confirm: "パスワードを認証する",
                current: "現在のパスワード",
              },
            },
            forgot: {
              title: "パスワードをお忘れですか？",
              description:
                "以下にメールアドレスを入力してください。リセットリンクが送信されます。",
              link: "リセットリンクを送信",
              back: "戻る",
              sign_in: "サインイン",
              form: {
                email: "Eメール",
              },
            },
            sidebar: {
              balance: "残高",
              reports: "レポート",
              exports: "輸出",
              manage: "アカウントの管理",
              support: "サポート",
              sign_out: "サインアウト",
            },
          },
          manage_campaigns: {
            title: "キャンペーン管理",
            description:
              "キャンペーンマネージャーへようこそ。下の表で現在のキャンペーンのステータスを確認するか、下の[新しいキャンペーン]ボタンをクリックして新しいキャンペーンを開始してください。",
            welcome: "キャンペーン管理へようこそ！",
            welcome_description:
              "TensorSocialによるキャンペーン管理へようこそ。今日から最初のキャンペーンを始めましょう！",
            new_campaign: "新しいキャンペーン",
            contact_manager: "マネージャーに連絡してください",
            create: {
              title: "新しいキャンペーンを開始する",
              description:
                "新しいキャンペーンへようこそ。以下のフォームに記入することから始めてください。TensorSocialの専門家がキャンペーンをすぐに開始します。",
              label_name: "キャンペーン名",
              placeholder_name: "名前キャンペーン...",
              label_description: "キャンペーンの説明",
              placeholder_description: "キャンペーンの説明...",
              label_networks: "ターゲットネットワーク",
              label_creator_list: "優先クリエイター",
              placeholder_creator_list:
                "クリエイターのリストを選択してください...",
              label_budget: "予算",
              placeholder_budget: "ご希望の予算を入力してください...",
              label_date: "開始日",
            },
            campaigns_table: {
              name_header: "名前",
              category_header: "カテゴリ",
              networks_header: "ネットワーク",
              creators_header: "クリエイター",
              budget_header: "予算",
              status_header: "ステータス",
            },
          },
          creator_lists: {
            title: "クリエイターリスト",
            description:
              "検出中に見つかった作成者は、簡単にリストに整理できます。以下のリストを選択して、必要なクリエイターのレビュー、管理、追跡を開始してください。",
            welcome: "クリエイターリストへようこそ！",
            welcome_description:
              "TensorSocialによるクリエイターリストへようこそ。ディスカバリーページから直接リストにクリエイターを追加してください。",
            new_list: "新しいリスト",
            list_table: {
              name_header: "名前",
              networks_header: "ネットワーク",
              creators_header: "クリエイター",
              reach_header: "リーチ",
              modified_header: "最終変更",
              creator_header: "クリエイター",
              followers_header: "フォロワー",
              engagement_header: "エンゲージメント",
              date_added_header: "追加された日付",
            },
          },
          dashboard: {
            title: "ダッシュボード",
            header: {
              title: "ようこそ！",
              description:
                "ディスカバリーとレポートをチェックして、ソーシャルメディアマーケティングのパフォーマンスを10倍に高めましょう",
            },
            creator_tracking: {
              title: "追跡された影響力者",
              description: "マイアカウント",
              view_report_button: "レポートを表示",
              time_period: "過去30日間",
              new_creator: {
                tab_title: "新しく追加する",
              },
            },
            getting_started: {
              learn_more: "もっと詳しく知る",
              title: "TensorSocial入門",
              description: "チュートリアル",
            },
            quick_searches: {
              title: "クイックスタートディスカバリー検索",
              description:
                "これらのDiscoveryプリセットを使用してクリエイター検索を開始します",
            },
            search: {
              description: "クリエイターを発見し",
              placeholder: "アカウントのユーザー名を入力してください...",
              discovery_link_count_fallback: "250M",
              discovery_link_text: "クリエイター",
              discovery_link_appended_text: "データベースでは",
            },
          },
          discovery: {
            title: "発見",
            audience: "オーディエンス",
            influencer: "インフルエンサー",
            results: "の検索結果",
            keywords: "キーワードを検索",
            view: "レポートを表示",
            fields: {
              select_interval: "間隔を選択",
              select_weight: "重量を選択",
              from: "から",
              to: "に",
            },
            chart: {
              title: "過去の輸出",
              filters: "フィルタ",
              date: "日にち",
              search: "検索",
              export: "輸出",
              example: "例",
              empty: "フィルタなし",
            },
            export: {
              title: "輸出記録",
              results: "の検索結果",
              remaining: "残りの輸出",
              empty: "利用可能なエクスポートはありません",
              contact: "メールをエクスポート",
              contact_tooltip:
                "連絡先の詳細がすべてのエクスポートに含まれるようになりました",
              placeholder: "エクスポート数",
              button: "エクスポート",
              waiting:
                "私たちはあなたのリストを準備しています、準備ができたら私たちはあなたに電子メールを送ります！",
              done: "終わり",
            },
            category: {
              psychographics: "サイコグラフィックス",
              demographics: "人口統計",
              geographics: "ジオグラフィー",
              performance: "パフォーマンス",
              other: "その他",
            },
            filters: {
              culture: {
                title: "文化",
                placeholder: {
                  audience: "趣味検索",
                  influencer: "趣味検索",
                },
              },
              brands: {
                title: "ブランド",
                placeholder: {
                  audience: "ブランド検索",
                  influencer: "ブランド検索",
                },
              },
              lookalikes: {
                title: "類似",
                placeholder: {
                  audience: "類似オーディエンス検索",
                  influencer: "類似オーディエンス検索",
                },
              },
              bio: {
                title: "プロフィール",
                placeholder: {
                  influencer: "経歴を検索",
                },
              },
              age: {
                title: "年齢",
              },
              gender: {
                title: "性別",
                options: {
                  male: "男",
                  female: "女性",
                  male_female: "男性か女性",
                  neutral: "ジェンダーニュートラル",
                },
              },
              language: {
                title: "言語",
                placeholder: {
                  audience: "言語検索",
                  influencer: "言語検索",
                },
              },
              ethnicity: {
                title: "民族性",
              },
              location: {
                title: "所在地",
                placeholder: {
                  country: "検索場所",
                  city: "検索場所",
                },
              },
              followers: {
                title: "フォロワー",
                youtube: "サブスクライバー",
              },
              engagements: {
                title: "エンゲージメント",
                rate: "エンゲージメント率",
              },
              last_post: {
                title: "最後の投稿",
                youtube: "最終アップロード",
                options: {
                  one_month: "1ヶ月",
                  three_months: "3ヵ月",
                  six_months: "6ヵ月",
                },
              },
              views: {
                title: "ビュー",
              },
              reels: {
                title: "リールプレイ",
              },
              account_type: {
                title: "アカウントの種類",
                options: {
                  regular: "通常のアカウント",
                  business: "事業者",
                  creator: "クリエイター",
                },
              },
              audience_type: {
                title: "オーディエンスの種類",
                options: {
                  any: "どれでも",
                  likers: "エンゲージャーズ",
                  followers: "フォロワー",
                },
              },
              partnerships: {
                title: "パートナーシップス",
                placeholder: {
                  influencer: "ブランド検索",
                },
              },
              contacts: {
                title: "連絡先一覧",
                placeholder: {
                  influencer: "連絡先検索",
                },
              },
              other: {
                verified: "認証されたアカウントのみ",
                credible: "信頼できるアカウントのみ",
                private: "プライベートアカウントを除外する",
                audience: "オーディエンスデータがある",
                contact: "連絡先一覧情報がある",
                sponsored: "スポンサーされてる投稿がある",
              },
            },
          },
          interests: {
            television_film: "テレビ＆映画",
            music: "音楽",
            shopping_retail: "ショッピング＆小売",
            coffee_tea_beverages: "コーヒー、紅茶、飲料",
            camera_photography: "カメラと写真",
            clothes_shoes: "洋服、靴、ハンドバッグ、アクセサリー",
            beer_wine: "ビール、ワイン、スピリッツ",
            sports: "スポーツ",
            electronics_computers: "科学 & テクノロジー",
            gaming: "ゲーム",
            activewear: "アクティブウェア",
            art_design: "アートデザイン",
            travel_tourism: "旅行、観光、航空",
            business_careers: "ビジネス",
            beauty_cosmetics: "美容・化粧品",
            healthcare_medicine: "ヘルスケアと医学",
            jewelry_watches: "ジュエリー＆時計",
            restaurants_grocery: "レストラン、食品、食料品",
            toys_children: "おもちゃ、子供、赤ちゃん",
            fitness_yoga: "フィットネス＆ヨガ",
            wedding: "結婚式",
            tobacco_smoking: "タバコと喫煙",
            pets: "ペット",
            healthy_lifestyle: "健康的な生活様式",
            luxury_goods: "高級品",
            furniture_garden: "住まい & 暮らし",
            friends_family: "友達、家族、人間関係",
            cars_motorbikes: "自動車",
          },
          languages: {
            italian: "イタリア語",
            spanish: "スペイン語",
            polish: "ポーランド語",
            korean: "韓国語",
            finnish: "フィンランド語",
            thai: "タイ語",
            malay: "マレーシア語",
            dutch: "オランダ語",
            swedish: "スウェーデン語",
            arabic: "アラビア語",
            hebrew: "ヘブライ語",
            danish: "デンマーク語",
            ukrainian: "ウクライナ語",
            english: "英語",
            greek: "ギリシャ語",
            portuguese: "ポルトガル語",
            russian: "ロシア語",
            japanese: "日本語",
            hindi: "ヒンディー語",
            turkish: "トルコ語",
            french: "フランス語",
            persian: "ペルシア語",
            catalan: "カタロニア語",
            hungarian: "ハンガリー語",
            czech: "チェコ語",
            german: "ドイツ語",
            indonesian: "インドネシア語",
            chinese: "中国語",
            norwegian: "ノルウェー語",
            basque: "バスク語",
            urdu: "ウルドゥー語",
            macedonian: "マケドニア語",
            tagalog: "タガログ語",
            lithuanian: "リトアニア語",
            albanian: "アルバニア語",
            vietnamese: "ベトナム語",
          },
          reports: {
            title: "レポート",
            splash: {
              title: "インフルエンサーレポート",
              description:
                "以下にソーシャルハンドルを入力して、人口統計、地理、サイコグラフィック、およびパフォーマンスの洞察を簡潔なレポートで取得します。",
              placeholder: "ユーザー名を検索",
              button: "分析する",
              filter_placeholder: "過去のレポートを検索",
              chart: {
                title: "過去のレポート",
                followers: "フォロワー",
                engagements: "エンゲージメント",
                date: "日にち",
                view: "意見",
                view_report: "レポートを表示",
                example: "例",
              },
            },
            global: {
              placeholder: "検索...",
            },
            add_to_list: "クリエイターリストに追加",
            share: "共有",
            generate: "レポートを表示",
            business: "ビジネスアカウント",
            contact: "連絡先を表示",
            influencer: "インフルエンサー",
            audience: "オーディエンス",
            posts: "投稿",
            stories: "Stories",
            view_more: "もっと見る",
            download: "ダウンロード",
            chart: {
              contact: "コンタクト",
              type: "種類",
              value: "結果",
              brand: "ブランド",
              country: "国",
              state: "州",
              city: "都市",
              age: "年",
              gender: "性別",
              interest: "興味",
              followers: "フォロワー",
              likes: "平均的ないいね",
              percentage: "百分率",
            },
            influencer_details: {
              engagements: "エンゲージメント",
              followers: "フォロワー数",
              subscribers: "サブスクライバー",
              following: "従う",
              likes: "平均的ないいね",
              views: "平均視聴回数",
              comments: "平均的なコメント",
              reels_plays: "Avg Reels Plays",
              price: "投稿あたりの推定価格",
              location: "位置",
              paid: "有料ポストパフォーマンス",
              month: "今月",
              trending: {
                title: "傾向分析",
                up: "アカウントは上昇傾向にあります",
                down: "アカウントは減少傾向にあります",
                neutral: "アカウントは中立です",
                bad: "悪い",
                normal: "正常",
                good: "良い",
              },
              engagement_rate: {
                title: "エンゲージメント率",
                graph: "同様のアカウント",
                accounts: "アカウント",
              },
              relevant_topics: "関連トピック",
              lookalikes: "類似",
              engagements_spread: {
                title: "最後の投稿のエンゲージメント",
                date: "投稿日",
                likes: "平均的ないいね",
                comments: "コメント",
                post: "投稿",
                view: "投稿を見る",
              },
              hashtags: {
                title: "人気のハッシュタグ",
                hashtag: "ハッシュタグ",
                percent: "パーセント",
              },
              mentions: {
                title: "人気のある言及",
                mention: "言及",
                percent: "パーセント",
              },
              brand_affinity: "インフルエンサーブランドの親和性",
              interests: "インフルエンサー文化",
            },
            audience_details: {
              title: "オーディエンスの詳細",
              tabs: {
                followers: "フォロワー",
                likes: "好き",
              },
              interests: "観客文化",
              lookalikes: "観客のそっくりさん",
              brand_affinity: "オーディエンスブランドの親和性",
              reach: "オーディエンスの到達可能性",
              gender: "性別グラフ",
              age_gender: {
                title: "年齢と性別のグラフ",
                age: "年齢グラフ",
                female: "女性の年齢グラフ",
                male: "男性の年齢グラフ",
              },
              location: {
                city: "都市別の場所",
                state: "州別の場所",
                country: "国別の場所",
                ethnicity: "民族性",
                language: "言語",
              },
              followers: {
                credibility: "フォロワーの信頼性",
                notable: "注目のユーザー",
              },
              likes: {
                credibility: "エンゲージメントの信頼性",
                notable: "注目すべきいいね",
              },
            },
            post_details: {
              tabs: {
                popular: "人気",
                sponsored: "後援",
              },
              popular: "人気の投稿",
              sponsored: "スポンサー投稿",
            },
            story_details: {
              title: "ストーリーの詳細",
              reach: "推定リーチ",
              back: "推定リウォッチ",
              swipe_ups: "推定スワイプアップ",
              link_clicks: "推定リンククリック数",
              day: "1日あたりのストーリー",
              impression: "推定インプレッション",
              forwards: "推定フォワード",
              replies: "推定返信数",
              current: "現在のストーリー数",
              empty: "このアカウントには現在、表示するストーリーがありません。",
              private:
                "このアカウントは非公開です。ストーリーを取得できません。",
            },
          },
          activity: {
            title: "活動",
          },
          errors: {
            no_record: "レコードが見つかりません",
          },
        },
      },
    },
  });

export default i18n;
